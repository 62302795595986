<template>
  <v-row>
    <v-col v-if="loading" cols="12" class="text-center">
      <v-progress-circular color="primary" indeterminate />
    </v-col>
    <template v-else>
      <!-- <program-empty v-if="false" @create-topic="createTopic"> </program-empty> -->
      <template>
        <v-col cols="12">
          <v-row align="center" class="no-gutters">
            <v-col md="6" class="py-0">
              <h3 class="font-weight-bold">Program {{ this.titleP }}</h3>
            </v-col>
            <v-spacer></v-spacer>
            <v-col id="search" md="6" class="text-right py-0">
              <v-row
                no-gutters
                class="flex-nowrap"
                align="end"
                style="float: right"
              >
                <!-- <v-menu offset-y> -->
                <!-- <v-btn class="mr-4" color="secondary" text> -->
                <!-- slot="activator"
                  slot-scope="{ on, attrs }"
                  v-bind="attrs"
                  v-on="on" -->
                <!-- Sort by <v-icon>$dropdown</v-icon> -->
                <!-- </v-btn> -->
                <!-- <v-list>
                  <v-list-item v-for="(by, index) in ['name']" :key="index">
                    <v-list-item-title>{{ by }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu> -->
                <v-text-field
                  outlined
                  append-icon="$search"
                  class="pt-0 mt-0 mr-4"
                  placeholder="Search"
                  solo
                  flat
                  v-model="name_topic"
                  @keydown.enter="fetchTopics"
                ></v-text-field>
                <v-btn @click="createTopic" color="primary">
                  <span> <v-icon>$add</v-icon> Topik Baru </span>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="!topics.length">
          <v-row justify="center">
            <v-col md="4" sm="6">
              <v-img
                class="col-10 mx-auto mt-12 mb-4"
                src="@/assets/images/data_empty.png"
              ></v-img>
              <h6 class="text-h6 text-center">Tidak ada topik</h6>
              <p class="text-center text--secondary">
                klik tombol Topik Baru untuk menambahkan topik baru
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else cols="12">
          <v-expansion-panels flat multiple popout>
            <v-expansion-panel
              :dropzone="$can('edit', 'Program')"
              v-for="(topic, index) in topics"
              :key="'A' + index"
              class="mb-4"
            >
              <v-expansion-panel-header
                disable-icon-rotate
                :draggable="$can('edit', 'Program')"
                @dragstart.native="handleDragStart(index)"
                @dragenter.native.prevent
                @dragover.native.prevent
                @drop.native="handleDrop(index, topics, 'topic')"
              >
                <h3 class="text-h6">{{ index + 1 }}. {{ topic.name }}</h3>
                <template slot="actions">
                  <can I="edit" a="Program">
                    <v-btn icon @click.stop="openEdit(topic)"
                      ><v-icon>$edit</v-icon></v-btn
                    >
                  </can>
                  <can I="delete" a="Program">
                    <v-btn icon @click.stop="openDel(topic.id, topic.name)"
                      ><v-icon>$delete</v-icon>
                    </v-btn>
                  </can>
                  <v-btn icon><v-icon>$expand</v-icon></v-btn>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                style="border-top: 1px solid lightgrey"
              >
                <v-row>
                  <v-col
                    v-if="!!activities.find((v) => v.topic == topic.id)"
                    cols="12"
                  >
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th v-for="(header, index) in headers" :key="index">
                            <span class="font-weight-medium">{{ header }}</span>
                          </th>
                          <can :I="['edit', 'delete']" a="Program">
                            <th>Action</th>
                          </can>
                        </tr>
                      </thead>
                      <tbody v-if="$can('edit', 'Program')">
                        <tr
                          v-for="(activity, index) in activities.find(
                            (v) => v.topic == topic.id
                          ).data"
                          :key="index"
                          draggable
                          @dragstart="handleDragStart(index)"
                          @dragenter.prevent
                          @dragover.prevent
                          @drop="
                            handleDrop(
                              index,
                              activities.find((v) => v.topic == topic.id).data,
                              'activity'
                            )
                          "
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ activity.title }}</td>
                          <td class="text-capitalize">
                            {{ activity.type | getType }}
                          </td>
                          <td>
                            {{
                              activity.settings.estimated_duration | getDuration
                            }}
                          </td>
                          <can :I="['edit', 'delete']" a="Program">
                            <td>
                              <can I="edit" a="Program">
                                <v-btn icon :to="editActivity(activity)"
                                  ><v-icon>$edit</v-icon></v-btn
                                >
                              </can>
                              <can I="delete" a="Program">
                                <v-btn icon @click="delActivity(activity)"
                                  ><v-icon>$delete</v-icon></v-btn
                                >
                              </can>
                              <can I="import" a="Question">
                                <v-btn
                                  v-if="
                                    [
                                      'pre-test',
                                      'post-test',
                                      'quiz',
                                      'exam',
                                    ].includes(activity.type)
                                  "
                                  icon
                                  @click.stop="importQuestions(activity)"
                                  ><v-icon>ri-upload-line</v-icon>
                                </v-btn>
                              </can>
                            </td>
                          </can>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr
                          v-for="(activity, index) in activities.find(
                            (v) => v.topic == topic.id
                          ).data"
                          :key="index"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ activity.title }}</td>
                          <td class="text-capitalize">
                            {{ activity.type | getType }}
                          </td>
                          <td>
                            {{
                              activity.settings.estimated_duration | getDuration
                            }}
                          </td>
                          <can :I="['edit', 'delete']" a="Program">
                            <td>
                              <can I="edit" a="Program">
                                <v-btn icon :to="editActivity(activity)"
                                  ><v-icon>$edit</v-icon></v-btn
                                >
                              </can>
                              <can I="delete" a="Program">
                                <v-btn icon @click="delActivity(activity)"
                                  ><v-icon>$delete</v-icon></v-btn
                                >
                              </can>
                            </td>
                          </can>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      text
                      :to="{
                        name: 'activity-create',
                        params: { program: topic.program.id, topic: topic.id },
                      }"
                    >
                      <span>+ Tambah aktivitas</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-dialog v-model="modalTopik.show" width="600px" persistent>
            <v-card elevation="0" rounded="lg" outlined>
              <v-card-title>
                <v-icon>$warning </v-icon> Hapus Topik
              </v-card-title>
              <v-divider class="color--text mb-4"></v-divider>
              <v-card-text>
                <template>
                  <span
                    >Apakah anda yakin ingin menghapus topik ini
                    {{ modalTopik.name }}?</span
                  >
                </template>
              </v-card-text>
              <v-card-actions class="pa-4" style="background: #f8f9fa">
                <v-spacer></v-spacer>
                <v-btn outlined @click="modalTopik.show = false"> Batal </v-btn>
                <v-btn
                  @click="hapus(modalTopik.id)"
                  color="primary"
                  elevation="0"
                  :loading="submit"
                >
                  Ya. Hapus!
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="modalEdit.show" width="650px" persistent>
            <v-card elevation="0" rounded="lg" outlined>
              <v-card-title>
                <v-icon>$warning </v-icon> Edit Topik
              </v-card-title>
              <v-divider class="color--text mb-4"></v-divider>
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12">
                    <div class="font-weight-medium mb-2">Judul Topik</div>
                    <v-text-field
                      outlined
                      v-model="modalEdit.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <div class="d-flex justify-start font-weight-medium">
                          <div>Durasi Topik Selanjutnya</div>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-on="on" v-bind="attrs" small right>
                                ri-question-line
                              </v-icon>
                            </template>
                            <div style="font-size: 12px; width: 300px">
                              Durasi topik digunakan untuk mengatur rentang
                              waktu user dapat mengakses topik selanjutnya.
                              <br />
                              Dihitung sejak penyelesaian aktivitas terakhir
                              pada topik ini.
                              <!-- Rentang waktu user dapat mengakses topik berikutnya. <br/>
                            Durasi topik digunakan untuk mengatur topik selanjutnya. <br/>
                            Dihitung sejak penyelesaian aktivitas terakhir pada topik ini. -->
                            </div>
                          </v-tooltip>
                        </div>
                        <div class="grey--text" style="font-size: 13px">
                          Pengaturan durasi topik dalam format hari
                        </div>
                        <v-expand-transition>
                          <v-text-field
                            outlined
                            type="number"
                            placeholder="Durasi dalam hari. Misal: 1"
                            v-show="modalEdit.duration"
                            v-model="modalEdit.delay_topic_duration"
                          ></v-text-field>
                        </v-expand-transition>
                        <div class="primary--text" style="font-size: 10px">
                          *Default durasi topik selanjutnya 1 hari setelah
                          pengerjaan setiap topik
                        </div>
                      </v-col>
                      <v-col>
                        <v-switch
                          v-model="modalEdit.duration"
                          inset
                          :label="modalEdit.duration ? 'Aktif' : 'Tidak Aktif'"
                        >
                        </v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <div class="d-flex justify-start font-weight-medium">
                          <div>Verifikasi Wajah</div>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-on="on" v-bind="attrs" small right>
                                ri-question-line
                              </v-icon>
                            </template>
                            <div style="font-size: 12px">
                              Verifikasi Wajah: Data wajah akan digunakan untuk
                              mengonfirmasi keaslian identitas peserta
                            </div>
                          </v-tooltip>
                        </div>
                        <div class="grey--text" style="font-size: 13px">
                          Verifikasi wajah untuk peserta kelas prakerja
                        </div>
                      </v-col>
                      <v-col>
                        <v-switch
                          v-model="modalEdit.use_liveness"
                          inset
                          :label="
                            modalEdit.use_liveness ? 'Aktif' : 'Tidak Aktif'
                          "
                        >
                        </v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="pa-4" style="background: #f8f9fa">
                <v-spacer></v-spacer>
                <v-btn outlined @click="modalEdit.show = false"> Batal </v-btn>
                <v-btn
                  @click="ubah(modalEdit.id)"
                  color="primary"
                  elevation="0"
                  :loading="submit"
                >
                  Ya. Ubah!
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="modalAktifitas.show" width="600px" persistent>
            <v-card elevation="0" rounded="lg" outlined>
              <v-card-title>
                <v-icon>$warning </v-icon> Hapus Aktifitas
              </v-card-title>
              <v-divider class="color--text mb-4"></v-divider>
              <v-card-text>
                <template>
                  <span>Apakah anda yakin ingin menghapus Aktifitas ini ?</span>
                </template>
              </v-card-text>
              <v-card-actions class="pa-4" style="background: #f8f9fa">
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="modalAktifitas.loading"
                  outlined
                  @click="modalAktifitas.show = false"
                >
                  Batal
                </v-btn>
                <v-btn
                  :loading="modalAktifitas.loading"
                  @click="hapusAktivitas"
                  color="primary"
                  elevation="0"
                >
                  Ya. Hapus!
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="modalImport.show" width="600px" persistent>
            <v-card elevation="0" rounded="lg" outlined>
              <v-card-title>
                <v-icon class="mr-2">ri-upload-line</v-icon> Import Questions
              </v-card-title>
              <v-divider class="color--text mb-4"></v-divider>
              <v-card-text>
                <div>
                  <div>Pilih File</div>
                  <v-file-input
                    v-model="modalImport.file"
                    outlined
                    accept=".xlsx"
                    prepend-icon=""
                    append-icon="ri-file-line"
                  ></v-file-input>
                </div>
              </v-card-text>
              <v-card-actions class="pa-4" style="background: #f8f9fa">
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="modalImport.loading"
                  outlined
                  @click="modalImport.show = false"
                >
                  Batal
                </v-btn>
                <v-btn
                  :loading="modalImport.loading"
                  @click="handleImportQuestions"
                  color="primary"
                  elevation="0"
                >
                  Import!
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-snackbar
            top
            v-model="snackbar.state"
            color="primary"
            outlined
            :timeout="timeout"
          >
            <div v-html="snackbar.text"></div>
            <template v-slot:action="{ attrs }">
              <v-btn
                small
                icon
                color="error"
                v-bind="attrs"
                @click="snackbar.state = false"
              >
                <v-icon>$close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </template>
    </template>
  </v-row>
</template>

<script>
import { URL_API } from "@/constants/api";

import { mapState } from "vuex";
import { programImportQuestions } from "../../services/program";
// import ProgramEmpty from "./components/Empty.vue";

export default {
  name: "program-detail",
  filters: {
    getType: (val) =>
      val == "membaca-e-book" ? "Membaca e-book" : val.replaceAll("_", " "),
    getDuration: (val) => {
      const hours = (parseInt(val / 60) || 0).toFixed().padStart(2, 0);
      const minutes = (val % 60).toFixed().padStart(2, 0);
      return `${hours}:${minutes}:00`;
    },
  },
  // components: { ProgramEmpty },
  data: () => {
    return {
      modalTopik: {
        show: false,
        id: "",
      },
      modalAktifitas: {
        show: false,
        topic_id: null,
        id: null,
      },
      modalEdit: {
        show: false,
        id: "",
        delay_topic_duration: 0,
        duration: false,
        use_liveness: false,
      },
      modalImport: {
        show: false,
        data: null,
        file: null,
        loading: false,
      },
      title_topik: "Pengenalan Program",
      topics: {},
      title: "",
      submit: false,
      snackbar: {
        state: false,
        text: "",
      },
      timeout: 5000,
      id: "",
      name_topic: "",
      loading: null,
      reorder: null,
      titleP: "",
    };
  },
  watch: {
    "modalEdit.duration": {
      handler(value) {
        // console.log(value);
        if (!value) return (this.modalEdit.delay_topic_duration = 0);
      },
      deep: true,
    },
  },
  computed: {
    program() {
      return {
        title: "Topik",
      };
    },
    headers() {
      return ["No", "Judul aktivitas", "Jenis aktivitas", "Durasi"];
    },
    activities() {
      return [
        {
          title: "Soal Test",
          type: "Mengerjakan Soal",
          duration: "00:20:00",
          grade: 100,
        },
        {
          title: "Introduction",
          type: "Menonton Video",
          duration: "00:05:00",
          grade: 80,
        },
      ];
    },
    ...mapState("activity", { activities: (state) => state.list }),
  },
  created() {
    this.$emit("page-changed", 1, {
      title: this.program.title,
      link: this.$route.path,
    });
    this.fetchTopics();
    this.fetchDetailProgram();
  },
  methods: {
    handleImportQuestions() {
      const { data, file } = this.modalImport;
      if (!data || !file) {
        this.snackbar.text = "File belum dipilih";
        this.snackbar.state = true;
        return;
      }

      this.modalImport.loading = true;
      programImportQuestions(data.id, file).then(res => {
        this.snackbar.text = res.data.message;
        this.snackbar.state = true;
        this.modalImport.show = false;
      }).catch((err) => {
        this.snackbar.text = err.message;
        this.snackbar.state = true;
        throw err
      })
      .finally(() => {
        this.modalImport.loading = false;
      });
    },
    importQuestions(activity) {
      this.modalImport.show = true;
      this.modalImport.data = activity;
    },
    async fetchTopics() {
      this.loading = true;
      try {
        await this.axios
          .get(`${URL_API.PROGRAM.TOPIC.LIST}`, {
            params: {
              sort: "seq",
              sort_type: "asc",
              limit: "-1",
              program_id: this.$route.params.id,
              q: this.name_topic,
            },
          })
          .then((res) => {
            this.topics = res.data.data.list;
          });
        await this.topics.forEach((topic) => {
          this.$store.dispatch("activity/fetch", {
            program: topic.program.id,
            topic: topic.id,
            // params: { order_by: "created_at", dir: "asc" },
          });
        });
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    fetchDetailProgram() {
      this.axios
        .get(`${URL_API.PROGRAM.DETAIL(this.$route.params.id)}`)
        .then((res) => {
          this.titleP = res.data.data.title;
        });
    },
    createTopic() {
      this.$router.push({ name: "tna-create" });
    },
    editTopic(id) {
      this.$router.push({ name: "tna-edit", params: { tnaId: id } });
    },
    openDel(id, name) {
      this.modalTopik.show = true;
      this.modalTopik.id = id;
      this.modalTopik.name = name;
    },
    openEdit(topic) {
      // console.log(topic);
      this.modalEdit.show = true;
      this.modalEdit.id = topic.id;
      this.modalEdit.name = topic.name;
      this.modalEdit.delay_topic_duration = topic.setting.use_delay_next_topic;
      this.modalEdit.duration = topic.setting.use_delay_next_topic
        ? true
        : false;
      this.modalEdit.use_liveness = topic.setting.use_liveness;
    },
    editActivity(activity) {
      return {
        name: "activity-edit",
        params: {
          program: activity.program.id,
          topic: activity.topic.id,
          id: activity.id,
        },
      };
    },
    delActivity(data) {
      this.modalAktifitas.show = true;
      this.modalAktifitas.id = data.id;
      this.modalAktifitas.topic_id = data.topic.id;
    },
    async hapusAktivitas() {
      const data = this.modalAktifitas;
      this.$set(this.modalAktifitas, "loading", true);
      try {
        await this.$store.dispatch("activity/delete", {
          program: this.$route.params.id,
          topic: data.topic_id,
          id: data.id,
        });
        this.snackbar.text = `Aktivitas berhasil dihapus.`;
        this.snackbar.state = true;
        this.modalAktifitas.show = false;
        await this.topics.forEach((topic) => {
          this.$store.dispatch("activity/fetch", {
            program: topic.program.id,
            topic: topic.id,
          });
        });
      } catch (error) {
        this.snackbar.text = `Error: ${error.message}`;
        this.snackbar.state = true;
        this.modalAktifitas.show = false;
      }
      this.$set(this.modalAktifitas, "loading", false);
    },
    ubah(id) {
      if (
        this.modalEdit.duration &&
        Number(this.modalEdit.delay_topic_duration) <= 0
      ) {
        this.snackbar.state = true;
        this.snackbar.text = "Durasi Topik harus lebih dari 0";
        return;
      }

      let topicName = this.modalEdit.name;
      if (topicName.trim().length <= 0) {
        this.snackbar.state = true;
        this.snackbar.text = "Nama Topik Masih Kosong masih kosong";
        return;
      }

      this.id = id;
      let params = {
        name: this.modalEdit.name,
        status: "publish",
        program_id: this.$route.params.id,
        delay_topic_duration: parseInt(this.modalEdit.delay_topic_duration),
        use_liveness: this.modalEdit.use_liveness,
      };
      this.axios
        .put(`${URL_API.PROGRAM.TOPIC.UPDATE(this.id)}`, params)
        .then(() => {
          this.submit = false;
          this.snackbar.text = `Topik berhasil diubah`;
          this.snackbar.state = true;
          this.modalEdit.show = false;
          this.fetchTopics();
        })
        .catch((res) => {
          this.submit = false;
          if (res.response) {
            this.snackbar.text = res.response.data.message;
            this.snackbar.state = true;
          }
        });
    },
    hapus(id) {
      this.id = id;
      this.axios
        .delete(`${URL_API.PROGRAM.TOPIC.DELETE(this.id)}`)
        .then(() => {
          this.submit = false;
          this.snackbar.text = `Topik berhasil dihapus`;
          this.snackbar.state = true;
          this.modalTopik.show = false;
          this.fetchTopics();
        })
        .catch((res) => {
          this.submit = false;
          if (res.response) {
            this.snackbar.text = res.response.data.message;
            this.snackbar.state = true;
          }
        });
    },
    handleDragStart(index) {
      this.reorder = index;
    },
    async handleDrop(index, model, model_name) {
      try {
        if (model_name == "activity") {
          const payload = {
            program: this.$route.params.id,
            topic: model[index].topic.id,
            data: { from: this.reorder, to: index },
          };
          await this.$store.dispatch("activity/reorder", payload);
        } else {
          const payload = {
            program_id: this.$route.params.id,
            topic_id: model[this.reorder].id,
            to: model[index].seq,
          };
          await this.axios.put(`${URL_API.PROGRAM.TOPIC.REORDER}`, payload);
        }
        const spliced = model.splice(this.reorder, 1);
        model.splice(index, 0, ...spliced);
        this.fetchTopics();
      } catch (error) {
        let message = error.message;
        if (error.response?.data?.message) {
          message = error.response?.data?.message;
        }
        this.snackbar.text = `Error: ${message}`;
        this.snackbar.state = true;
        console.error(error);
      }
      this.reorder = null;
    },
  },
};
</script>
<style></style>
